











































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Calculator, Check, Subheaders } from '@/store/list/types.ts'

const listModule = namespace('list')

@Component({
  name: 'AppChecksRadio',
  components: {}
})
export default class AppChecksRadio extends Vue {
  @listModule.Action('fetchCalculatorData') fetchCalculatorData!: Function
  @listModule.State('calculation') calculation!: Array<Calculator>
  @listModule.Mutation('addOrRemoveCheckboxItem') addOrRemoveCheckboxItem!: Function
  @listModule.Mutation('addOrRemoveRadioItem') addOrRemoveRadioItem!: Function

  mounted () { // start json server: json-server --watch public/calculator.json
    this.fetchCalculatorData()
  }

  get calculatorLength () { // keeps expansion panels open
    const calculatorLength = this.calculation.length
    return Array.from(Array(calculatorLength).keys())
  }

  onRadioButtonChanged (item: Check, subheader: Subheaders) {
    this.addOrRemoveRadioItem({
      item: item,
      subheader: subheader
    })
  }

  onCheckboxChanged (item: Check, subheader: Subheaders) {
    this.addOrRemoveCheckboxItem({
      item: item,
      subheader: subheader
    })
  }
}
