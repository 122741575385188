




















import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Check } from '@/store/list/types'

const listModule = namespace('list')

@Component({
  name: 'AppSideFloaterCurrentTotal'
})
export default class AppSideFloaterCurrentTotal extends Vue {
  public minTotal = 4000
  public maxTotal = 6000
  @listModule.State('selectedOptions') selectedOptions!: Map<string, Check>

  mounted () {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'list/addOrRemoveRadioItem' || mutation.type === 'list/addOrRemoveCheckboxItem') {
        this.calculateTotal()
      }
    })
  }

  calculateTotal () {
    const checkArray = Array.from(this.selectedOptions.values())
    const multiply = checkArray.filter(item => item.costMultiply).reduce((num, item) => num * item.costMultiply, 1)

    const sumMin = checkArray.filter(item => item.costAddMin).reduce((num, current) => num + current.costAddMin * multiply, 0)
    const sumMax = checkArray.filter(item => item.costAddMax).reduce((num, current) => num + current.costAddMax * multiply, 0)

    this.maxTotal = sumMax + 6000
    this.minTotal = sumMin + 4000
  }
}
