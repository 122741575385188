








import { Component, Vue } from 'vue-property-decorator'
import AppSideFloaterCurrentTotal from '@/components/AppSideFloaterCurrentTotal.vue'
import AppSideFloaterExpandables from '@/components/AppSideFloaterExpandables.vue'

@Component({
  name: 'Floater',
  components: {
    AppSideFloaterCurrentTotal,
    AppSideFloaterExpandables
  }
})
export default class AppFloater extends Vue {
}
