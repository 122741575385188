








import { Component, Vue } from 'vue-property-decorator'
import DefaultLayout from '@/views/DefaultLayout.vue'
@Component({
  name: 'App',
  components: {
    DefaultLayout
  }
})

export default class App extends Vue {
}
