import { ActionTree, Module, MutationTree } from 'vuex'
import axios, { AxiosResponse } from 'axios'
import { Vue } from 'vue-property-decorator'
import { Calculator, CalculatorState, Check, Subheaders } from '@/store/list/types'
import { RootState } from '@/store/types'
export const URL = (process.env.VUE_APP_API_URL || 'http://localhost:3000/calculator.json')

const getDefaultState = () => {
  return {
    calculation: [],
    selectedOptions: new Map<string, Check>()
  }
}
const state = getDefaultState()

const actions: ActionTree<CalculatorState, RootState> = {
  fetchCalculatorData ({ commit }) {
    axios.get(URL)
      .then((response: AxiosResponse) => {
        commit('setCalculation', response.data)
      })
      .catch((reason) => {
        console.error(reason)
      })
  }
}

const mutations: MutationTree<CalculatorState> = {
  setCalculation (state, calculation: Array<Calculator>) {
    state.calculation = calculation
  },
  addOrRemoveCheckboxItem (state, { item, subheader }: {item: Check; subheader: Subheaders }) {
    if (state.selectedOptions.has(item.id.toString())) {
      state.selectedOptions.delete(item.id.toString())
    } else {
      state.selectedOptions.set(item.id.toString(), item)
    }
    if (item.isChecked) {
      subheader.isSelected++
    } else {
      subheader.isSelected--
    }
  },
  addOrRemoveRadioItem (state, { item, subheader }: { item: Check; subheaderName: string; subheader: Subheaders}) {
    subheader.isSelected++
    const selected = state.selectedOptions
    selected.set(subheader.name, item)
    Vue.set(state, 'selectedOptions', selected)
  },
  resetState (state) {
    Vue.set(state, 'calculation', getDefaultState().calculation)
  }
}

export const list: Module<CalculatorState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
}
