import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#f32636',
        secondary: '#919396',
        accent: '#efeff0',
        success: '#1d1d1b',
        font: '#171721'
      }
    },
    options: {
      customProperties: false
    }
  }
})
