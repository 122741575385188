





























import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Calculator } from '@/store/list/types'

const listModule = namespace('list')

@Component({
  name: 'AppSideFloaterExpandables'
})
export default class AppSideFloaterExpandables extends Vue {
  @listModule.Action('fetchCalculatorData') fetchCalculatorData!: Function
  @listModule.State('calculation') calculation!: Array<Calculator>
}

