























import { Component, Vue } from 'vue-property-decorator'
import AppChecksRadio from '@/components/AppChecksRadio.vue'
import AppFloater from '@/components/AppFloater.vue'

@Component({
  name: 'DefaultLayout',
  components: {
    AppChecksRadio,
    AppFloater
  }
})
export default class DefaultLayout extends Vue {
  // TODO
  public childData = 0

  updateTotal (variable: number) {
    this.childData = variable
  }
}
